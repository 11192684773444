<!-- 第三方账号绑定 -->
<template>
  <div class="do-page-thirdparty">
    <base-nav-bar @click-left="router.go(-1)" left-arrow title="第三方账号绑定">
    </base-nav-bar>
    <van-dialog
      v-model:show="showApple"
      title="解绑微信账号"
      class="do-com-dialog dialog-thirdparty"
      show-cancel-button
    >
      <div class="tip">
        你确定解绑？<br />
        解绑微信后将无法继续使用它登录
      </div>
    </van-dialog>

    <base-bscroll :safeareaNavbar="true" :options="{ bounce: true }">
      <div class="do-page-thirdparty-content">
        <div class="do-com-cellitem">
          <div style="display: flex; align-items: center">
            <img
              class="icon"
              :src="require('@/assets/images/my/weixin.png')"
            />绑定微信
          </div>
          <div class="right" @click="showApple = true">
            <span class="text">心想事成</span>
            <van-icon name="link-o" color="#3E4055" />
          </div>
        </div>
        <div class="do-com-cellitem">
          <div style="display: flex; align-items: center">
            <img
              class="icon"
              :src="require('@/assets/images/my/apple.png')"
            />绑定Apple ID
          </div>
          <div class="right">
            <span class="bind-btn"> 去绑定 </span>
            <van-icon name="arrow" color="rgba(197, 197, 200, 1)" />
          </div>
        </div>
      </div>
    </base-bscroll>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { ref } from "vue";
const router = useRouter();
const showApple = ref(false);
</script>

<style lang="scss">
.dialog-thirdparty {
  .van-dialog__content {
    text-align: center;
    font-size: 0.28rem;
    font-weight: 400;
    color: #969799;
  }
}

.do-page-thirdparty {
  .do-com-scroll-wrapper {
    background: $col_bg;
  }
  .do-com-cellitem {
    margin-bottom: 0.24rem;
  }
  .do-com-navbar {
    &::after {
      border-bottom-width: 0px !important;
    }
  }
  .icon {
    width: 0.4rem;
    height: 0.4rem;
    margin-right: 0.16rem;
  }
  &-content {
    padding: 0.26rem 0.24rem;

    .do-com-cellitem {
      .van-icon {
        margin-right: 0.1rem;
      }
    }
  }
  .bind-btn {
    font-size: 0.28rem;
    font-weight: 400;
    color: #0057ff;
    margin-right: 0.15rem;
  }
  .right-text {
    font-size: 0.28rem;
    font-weight: 400;
    color: rgba(3, 5, 15, 0.45);
    transition: all 0.3s;
  }
}
</style>
